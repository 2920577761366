import type { DividendHistoryFieldsFragment } from '@/gql/generated-api2'
import { FlagEnum, RegularFrequencyEnum } from '@/types/dividend'

type Props = {
  dividends: DividendHistoryFieldsFragment[]
}

// none TTM: 최근 정규 배당수량 * 프리퀀시에 따른 카운트
// legacy: getLatestRegularDividendItem
export default function getLatestRegularDividend({
  dividends,
}: Props): DividendHistoryFieldsFragment | null {
  return dividends.reduce((acc: DividendHistoryFieldsFragment | null, dividend) => {
    // 필터링
    const regularFrequency =
      RegularFrequencyEnum[dividend?.frequency?.toUpperCase() as keyof typeof RegularFrequencyEnum]
    const isRegularFrequency = !!regularFrequency
    const isCashFlag = dividend.flag !== FlagEnum.NONE && dividend.flag !== FlagEnum.STOCK
    const lastYear =
      new Date().getFullYear() - (regularFrequency === RegularFrequencyEnum.ANNUAL ? 2 : 1)
    const isRecentPayment = new Date(dividend.paymentDate).getFullYear() >= lastYear
    // 필터링

    if (isRegularFrequency && isCashFlag && isRecentPayment) {
      if (acc === null || acc.paymentDate < dividend.paymentDate) {
        // 배당 중 가장 최근 paymentDate 하나 추출
        acc = dividend
      }
    }

    return acc
  }, null)
}
