import type { DividendHistoryFieldsFragment } from '@/gql/generated-api2'
import { RegularFrequencyEnum } from '@/types/dividend'

import getLatestRegularDividendCount from './latest-regular-dividend-count'

type Props = {
  dividends: DividendHistoryFieldsFragment[]
  months?: number
}

export default function getLastOneYearRegularDividends({
  dividends,
  months = 18,
}: Props): DividendHistoryFieldsFragment[] {
  // 과거 특정 개월 수 만큼 배당 정보 뽑아냄
  const currentDate = new Date()
  const pastDate = new Date()
  pastDate.setMonth(pastDate.getMonth() - months)

  const dividendCount = getLatestRegularDividendCount({ dividends })

  return dividends
    .filter((dividend) => {
      const recordDate = new Date(dividend.recordDate)
      return (
        recordDate <= currentDate &&
        recordDate >= pastDate &&
        RegularFrequencyEnum[dividend.frequency?.toUpperCase() as keyof typeof RegularFrequencyEnum]
      )
    })
    .slice(0, dividendCount)
}
