import { UserAssetFieldsFragment, AssetCategory } from '@/gql/generated-api1'
import { EtcAssetCategoryEnum } from '@/types/asset'

export function isStock({ asset }: { asset: UserAssetFieldsFragment }): boolean {
  return !!(asset.assetCategory === AssetCategory.Company && asset.companyAsset)
}

export function isEtcStock({ asset }: { asset: UserAssetFieldsFragment }): boolean {
  return !!(asset.assetCategory === AssetCategory.EtcCompany && asset.etcCompanyAsset)
}

export function isCrypto({ asset }: { asset: UserAssetFieldsFragment }): boolean {
  return !!(asset.assetCategory === AssetCategory.CryptoCurrency && asset.cryptoCurrencyAsset)
}

export function isCurrency({ asset }: { asset: UserAssetFieldsFragment }): boolean {
  return !!(asset.assetCategory === AssetCategory.Currency && asset.currencyAsset)
}

export function isEtc({ asset }: { asset: UserAssetFieldsFragment }): boolean {
  return !!(asset.assetCategory === AssetCategory.Etc && asset.etcAsset)
}

export function isEtcCustom({ asset }: { asset: UserAssetFieldsFragment }): boolean {
  return !!(
    asset.assetCategory === AssetCategory.Etc &&
    asset.etcAsset?.category === EtcAssetCategoryEnum.CUSTOM
  )
}

export default function getAssetCategory({
  asset,
  isSeparateEtcCustom = false,
}: {
  asset: UserAssetFieldsFragment
  isSeparateEtcCustom?: boolean
}): AssetCategory | EtcAssetCategoryEnum.CUSTOM {
  if (isStock({ asset })) {
    return AssetCategory.Company
  }

  if (isEtcStock({ asset })) {
    return AssetCategory.EtcCompany
  }

  if (isCrypto({ asset })) {
    return AssetCategory.CryptoCurrency
  }

  if (isCurrency({ asset })) {
    return AssetCategory.Currency
  }

  if (isSeparateEtcCustom && isEtcCustom({ asset })) {
    return EtcAssetCategoryEnum.CUSTOM
  }

  return AssetCategory.Etc
}
