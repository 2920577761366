import Big from 'big.js'

import { exchangesStore } from '@/service/currency/store'

import type { IndexingByAllExchanges } from '../exchange/indexing-by-all-exchanges'

import type { CurrencyType } from './currency-exchanges-hook'

type Props = {
  price: string | number
  from?: string | null
  to?: string | null
  type?: CurrencyType
  exchanges?: IndexingByAllExchanges
}

export default function exchangeCurrency({
  price,
  from,
  to,
  type = 'fiat',
  exchanges = exchangesStore(),
}: Props) {
  if (!price || from === to || !from || !to) return price ? `${price}` : '0'

  const exchangeRate = exchanges[type][`${from}${to}`]?.exchangeRate
  return exchangeRate
    ? Big(+price)
        .mul(+exchangeRate)
        .toString()
    : '0'
}
