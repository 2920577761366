import type { DividendHistoryFieldsFragment } from '@/gql/generated-api2'

type Props = {
  dividendHistories: DividendHistoryFieldsFragment[]
}

export default function indexingDividendHistoiresByCompanyId({ dividendHistories }: Props): {
  [key in string]?: DividendHistoryFieldsFragment[]
} {
  // 정렬은 배당락일 내림차순 기준으로 서버에서 해줬다는 가정하에 하지 않음
  return dividendHistories.reduce(
    (acc: { [key in string]?: DividendHistoryFieldsFragment[] }, dividendHistory) => {
      const companyId = dividendHistory.company?.id ?? null
      if (companyId !== null) {
        if (Array.isArray(acc[companyId])) {
          acc[companyId]?.push(dividendHistory)
        } else {
          acc[companyId] = [dividendHistory]
        }
      }

      return acc
    },
    {},
  )
}
