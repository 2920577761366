import Big from 'big.js'

import type { DividendHistoryFieldsFragment } from '@/gql/generated-api2'
import type { RegularFrequencyCountPerYearEnum } from '@/types/dividend'
import exchangeCurrency from '@/service/currency/exchange'

import getLatestRegularDividend from './latest-regular-dividend'
import oneYearDividendAmountAsTerms from './one-year-dividends-amount-as-terms'

type Props = {
  dividends: DividendHistoryFieldsFragment[]
  dividendCount: RegularFrequencyCountPerYearEnum
  toCurrencySymbol: string
  taxRatio?: string
}

// none TTM: 최근 정규 배당수량 * 프리퀀시에 따른 카운트
export default function oneYearDividendAmountAsLatest({
  dividends,
  dividendCount,
  toCurrencySymbol,
  taxRatio = '0',
}: Props): string {
  const latestRegularDividend = getLatestRegularDividend({ dividends })

  return latestRegularDividend === null
    ? oneYearDividendAmountAsTerms({ dividends, dividendCount, toCurrencySymbol, taxRatio })
    : Big(
        exchangeCurrency({
          price: latestRegularDividend.amount ?? 0,
          from: latestRegularDividend.currency?.symbol,
          to: toCurrencySymbol,
        }),
      )
        .mul(dividendCount)
        .mul(1 - +taxRatio)
        .toString()
}
