import Big from 'big.js'

import type { DividendHistoryFieldsFragment } from '@/gql/generated-api2'
import type { RegularFrequencyCountPerYearEnum } from '@/types/dividend'
import exchangeCurrency from '@/service/currency/exchange'

import getLastOneYearRegularDividends from './last-one-year-regular-dividends'

type Props = {
  dividends: DividendHistoryFieldsFragment[]
  dividendCount: RegularFrequencyCountPerYearEnum
  toCurrencySymbol: string
  months?: number
  taxRatio?: string
}

// TTM: recordDate 기준으로 최근 일년 가량 배당수량 총 합
// legacy: getLastYearRegularDividendAmount, getLastYearRegularDividendItems
export default function oneYearDividendAmountAsTerms({
  dividends,
  dividendCount,
  toCurrencySymbol,
  months = 18,
  taxRatio = '0',
}: Props): string {
  const regularDividendsAsTerms = getLastOneYearRegularDividends({
    dividends,
    months,
  })

  // 특정 기간동안에 해당하는 배당 정보 없을 경우
  if (!regularDividendsAsTerms.length) return '0'

  const allAmount = regularDividendsAsTerms.slice(0, +dividendCount).reduce(
    (acc, dividend) =>
      Big(acc)
        .add(
          exchangeCurrency({
            price: dividend.amount ?? 0,
            from: dividend.currency?.symbol,
            to: toCurrencySymbol,
          }),
        )
        .toString(),
    '0',
  )

  return Big(allAmount)
    .mul(1 - +taxRatio)
    .toString()
}
