export enum EtcAssetCategoryEnum {
  REAL_ESTATE = 'REAL_ESTATE',
  GOLD = 'GOLD',
  CUSTOM = 'CUSTOM',
}

// 포트폴리오 자산 필터링 기능을 위함
export enum AssetCategroyTypeEnum {
  US = 'US',
  KR = 'KR',
  CA = 'CA',
  GB = 'GB',
  CN = 'CN',
  HK = 'HK',
  JP = 'JP',
  DE = 'DE',
  BE = 'BE',
  MX = 'MX',
  VN = 'VN',
  CRYPTO = 'CRYPTO',
  CASH = 'CASH',
  OTHER_SECURITIES = 'OTHER_SECURITIES',
}
