export enum FlagEnum {
  CASH = 'Cash',
  STOCK = 'Stock',
  NONE = 'needToCheck',
}

export enum FrequencyEnum {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  'SEMI-ANNUAL' = 'semi-annual',
  ANNUAL = 'annual',
  SUSPENDING = 'suspending',
  SPECIAL = 'special',
  WEEKLY = 'weekly',
  UNSPECIFIED = 'unspecified',
  IRREGULAR = 'irregular',
  OTHERS = 'others',
}

export enum RegularFrequencyEnum {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  'SEMI-ANNUAL' = 'semi-annual',
  ANNUAL = 'annual',
  SUSPENDING = 'suspending',
  WEEKLY = 'weekly',
}

export enum RegularFrequencyCountPerYearEnum {
  MONTHLY = 12,
  QUARTERLY = 4,
  'SEMI-ANNUAL' = 2,
  ANNUAL = 1,
  SUSPENDING = 0,
  WEEKLY = 52,
}
