import { CountryEnum } from '@/types/country'
import isOnlyNumber from '@/utils/regex/only-number'

type Props = {
  ticker: string
  country?: string | null
}

export default function isNumberTickerAndKorean({ ticker, country }: Props) {
  return isOnlyNumber(ticker) || country === CountryEnum.KR
}
