import type { DividendHistoryFieldsFragment } from '@/gql/generated-api2'
import { RegularFrequencyEnum } from '@/types/dividend'

import changeFrequencyToNumber from './change-frequency-to-number'

type Props = {
  dividends: DividendHistoryFieldsFragment[]
}

export default function getLatestRegularDividendCount({ dividends }: Props): number {
  // 정규 프리퀀시에 해당하는 가장 최근 배당정보
  const latestRegularDividend = dividends.find(
    (dividend) =>
      RegularFrequencyEnum[dividend?.frequency?.toUpperCase() as keyof typeof RegularFrequencyEnum],
  )
  return changeFrequencyToNumber(latestRegularDividend?.frequency)
}
