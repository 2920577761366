import Big from 'big.js'

import type { DividendHistoryFieldsFragment } from '@/gql/generated-api2'
import type { UserAssetFieldsFragment } from '@/gql/generated-api1'
import { isStock } from '@/service/asset/category'

import oneYearDividendAmountAsLatest from './one-year-dividends-amount-as-latest'
import oneYearDividendAmountAsTerms from './one-year-dividends-amount-as-terms'
import getLatestRegularDividendCount from './latest-regular-dividend-count'

const defaultValue = '0'

type OneYearDividendsAmountAsStock = {
  amount?: string | null
  needTtm?: boolean
  dividends: DividendHistoryFieldsFragment[]
  toCurrencySymbol: string
  taxRatio?: string
}

// 주식 자산으로 부터 산출
export function oneYearDividendsAmountAsStock({
  amount = '1',
  needTtm = false,
  dividends,
  toCurrencySymbol,
  taxRatio = '0',
}: OneYearDividendsAmountAsStock): string {
  const dividendCount = getLatestRegularDividendCount({ dividends })

  // 배당 정보에 정규배당 정보가 없을 suspending일 경우
  if (!dividendCount || !toCurrencySymbol) return defaultValue

  const dividendsAmountPrice = needTtm
    ? oneYearDividendAmountAsTerms({ dividends, dividendCount, toCurrencySymbol, taxRatio }) // ttm
    : oneYearDividendAmountAsLatest({ dividends, dividendCount, toCurrencySymbol, taxRatio }) // none ttm

  return Big(dividendsAmountPrice)
    .mul(+(amount ?? 0))
    .toString()
}

function isDividend(
  dividendsByCompanyId:
    | { [key in string]?: DividendHistoryFieldsFragment[] }
    | DividendHistoryFieldsFragment[],
): dividendsByCompanyId is DividendHistoryFieldsFragment[] {
  return Array.isArray(dividendsByCompanyId)
}

type Props = {
  asset: UserAssetFieldsFragment
  amount?: string | null
  dividendsByCompanyId:
    | { [key in string]?: DividendHistoryFieldsFragment[] }
    | DividendHistoryFieldsFragment[]
  toCurrencySymbol: string
  taxRatio?: string
}

// 포트폴리오 asset으로 부터 산출하도록 제작
export default function oneYearDividendsAmount({
  asset,
  amount = asset.amount,
  dividendsByCompanyId,
  toCurrencySymbol,
  taxRatio = '0',
}: Props): string {
  // 주식 자산만
  if (isStock({ asset })) {
    const companyId = asset.companyAsset?.id as string
    const dividends = isDividend(dividendsByCompanyId)
      ? dividendsByCompanyId
      : dividendsByCompanyId[companyId] ?? null

    // 배당 정보가 없을 경우
    if (dividends === null) {
      return defaultValue
    }

    return oneYearDividendsAmountAsStock({
      needTtm: !!asset.companyAsset?.needTtm,
      amount,
      dividends,
      toCurrencySymbol,
      taxRatio,
    })
  }

  return defaultValue
}
