import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Company = {
  __typename?: 'Company';
  companyName?: Maybe<CompanyNameLanguage>;
  country?: Maybe<Scalars['String']>;
  exchange?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isEtf?: Maybe<Scalars['Boolean']>;
  isinCode?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  paymentMonths?: Maybe<Array<Maybe<Scalars['String']>>>;
  sector?: Maybe<Scalars['String']>;
  stockPrice?: Maybe<StockPrice>;
  subExchange?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
};

export type CompanyNameLanguage = {
  __typename?: 'CompanyNameLanguage';
  en?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
};

export type CryptoCurrency = {
  __typename?: 'CryptoCurrency';
  currencyType?: Maybe<Scalars['String']>;
  decimalPoint?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<CryptoCurrencyLanguage>;
  symbol?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type CryptoCurrencyLanguage = {
  __typename?: 'CryptoCurrencyLanguage';
  en?: Maybe<Scalars['String']>;
  ko?: Maybe<Scalars['String']>;
};

export type Currency = {
  __typename?: 'Currency';
  decimalPoint?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
};

export type DividendHistory = {
  __typename?: 'DividendHistory';
  amount?: Maybe<Scalars['Float']>;
  company?: Maybe<Company>;
  currency?: Maybe<Currency>;
  exDividendDate?: Maybe<Scalars['DateTime']>;
  flag?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  recordDate?: Maybe<Scalars['DateTime']>;
};

export type DividendHistoryCountry = {
  __typename?: 'DividendHistoryCountry';
  countryCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  name?: Maybe<CompanyNameLanguage>;
};

export enum ExternalChannel {
  Ebest = 'EBEST',
  SangSangIn = 'SANG_SANG_IN'
}

export type Query = {
  __typename?: 'Query';
  dividendCalendar?: Maybe<Array<Maybe<DividendHistory>>>;
  dividendCalendarByCountry?: Maybe<Array<Maybe<DividendHistory>>>;
  dividendCalendarForExternalChannel?: Maybe<Array<Maybe<DividendHistory>>>;
  dividendCalendarForExternalChannelWithPagination?: Maybe<Array<Maybe<DividendHistory>>>;
  dividendHistories?: Maybe<Array<Maybe<DividendHistory>>>;
  dividendHistoriesInCompany?: Maybe<Array<Maybe<DividendHistory>>>;
  dividendHistoriesInFavoriteItemCategory?: Maybe<Array<Maybe<DividendHistory>>>;
  dividendHistoriesInPortfolios?: Maybe<Array<Maybe<DividendHistory>>>;
  dividendHistoriesInSnp500?: Maybe<Array<Maybe<DividendHistory>>>;
  supportedCompaniesByCountry?: Maybe<Array<Maybe<Company>>>;
  supportedCountryForDividendCalendar?: Maybe<Array<Maybe<DividendHistoryCountry>>>;
  supportedCryptoCurrencyBases?: Maybe<Array<Maybe<CryptoCurrency>>>;
};


export type QueryDividendCalendarArgs = {
  date?: InputMaybe<Scalars['String']>;
};


export type QueryDividendCalendarByCountryArgs = {
  country?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
};


export type QueryDividendCalendarForExternalChannelArgs = {
  channelName?: InputMaybe<ExternalChannel>;
  date?: InputMaybe<Scalars['String']>;
};


export type QueryDividendCalendarForExternalChannelWithPaginationArgs = {
  channelName?: InputMaybe<ExternalChannel>;
  date?: InputMaybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
};


export type QueryDividendHistoriesArgs = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryDividendHistoriesInCompanyArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};


export type QueryDividendHistoriesInFavoriteItemCategoryArgs = {
  favoriteItemCategorySlug?: InputMaybe<Scalars['String']>;
};


export type QueryDividendHistoriesInPortfoliosArgs = {
  portfolioSlugs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryDividendHistoriesInSnp500Args = {
  companyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QuerySupportedCompaniesByCountryArgs = {
  country?: InputMaybe<Scalars['String']>;
};

export type StockPrice = {
  __typename?: 'StockPrice';
  id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  priceChangePercent?: Maybe<Scalars['String']>;
};

export type DividendHistoryFieldsFragment = { __typename?: 'DividendHistory', id?: string | null, amount?: number | null, paymentDate?: any | null, recordDate?: any | null, exDividendDate?: any | null, frequency?: string | null, flag?: string | null, currency?: { __typename?: 'Currency', id?: string | null, unit?: string | null, symbol?: string | null, decimalPoint?: number | null } | null, company?: { __typename?: 'Company', id?: string | null, ticker?: string | null, country?: string | null, paymentMonths?: Array<string | null> | null, logoUrl?: string | null, companyName?: { __typename?: 'CompanyNameLanguage', ko?: string | null, en?: string | null } | null, stockPrice?: { __typename?: 'StockPrice', price?: number | null, priceChangePercent?: string | null } | null } | null };

export type DividendHistoryFieldsWithoutCompanyNameFragment = { __typename?: 'DividendHistory', id?: string | null, amount?: number | null, paymentDate?: any | null, recordDate?: any | null, exDividendDate?: any | null, frequency?: string | null, flag?: string | null, currency?: { __typename?: 'Currency', id?: string | null, unit?: string | null, symbol?: string | null, decimalPoint?: number | null } | null, company?: { __typename?: 'Company', id?: string | null, ticker?: string | null, country?: string | null, paymentMonths?: Array<string | null> | null, logoUrl?: string | null, stockPrice?: { __typename?: 'StockPrice', price?: number | null, priceChangePercent?: string | null } | null } | null };

export type DividendCalendarQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type DividendCalendarQuery = { __typename?: 'Query', dividendCalendar?: Array<{ __typename?: 'DividendHistory', id?: string | null, amount?: number | null, paymentDate?: any | null, recordDate?: any | null, exDividendDate?: any | null, frequency?: string | null, flag?: string | null, currency?: { __typename?: 'Currency', id?: string | null, unit?: string | null, symbol?: string | null, decimalPoint?: number | null } | null, company?: { __typename?: 'Company', id?: string | null, ticker?: string | null, country?: string | null, paymentMonths?: Array<string | null> | null, logoUrl?: string | null, companyName?: { __typename?: 'CompanyNameLanguage', ko?: string | null, en?: string | null } | null, stockPrice?: { __typename?: 'StockPrice', price?: number | null, priceChangePercent?: string | null } | null } | null } | null> | null };

export type DividendHistoriesQueryVariables = Exact<{
  companyIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DividendHistoriesQuery = { __typename?: 'Query', dividendHistories?: Array<{ __typename?: 'DividendHistory', id?: string | null, amount?: number | null, paymentDate?: any | null, recordDate?: any | null, exDividendDate?: any | null, frequency?: string | null, flag?: string | null, currency?: { __typename?: 'Currency', id?: string | null, unit?: string | null, symbol?: string | null, decimalPoint?: number | null } | null, company?: { __typename?: 'Company', id?: string | null, ticker?: string | null, country?: string | null, paymentMonths?: Array<string | null> | null, logoUrl?: string | null, companyName?: { __typename?: 'CompanyNameLanguage', ko?: string | null, en?: string | null } | null, stockPrice?: { __typename?: 'StockPrice', price?: number | null, priceChangePercent?: string | null } | null } | null } | null> | null };

export type DividendHistoriesInPortfoliosQueryVariables = Exact<{
  slugs: Array<Scalars['String']> | Scalars['String'];
}>;


export type DividendHistoriesInPortfoliosQuery = { __typename?: 'Query', dividendHistoriesInPortfolios?: Array<{ __typename?: 'DividendHistory', id?: string | null, amount?: number | null, paymentDate?: any | null, recordDate?: any | null, exDividendDate?: any | null, frequency?: string | null, flag?: string | null, currency?: { __typename?: 'Currency', id?: string | null, unit?: string | null, symbol?: string | null, decimalPoint?: number | null } | null, company?: { __typename?: 'Company', id?: string | null, ticker?: string | null, country?: string | null, paymentMonths?: Array<string | null> | null, logoUrl?: string | null, stockPrice?: { __typename?: 'StockPrice', price?: number | null, priceChangePercent?: string | null } | null } | null } | null> | null };

export type DividendHistoriesInFavoriteItemCategoryQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type DividendHistoriesInFavoriteItemCategoryQuery = { __typename?: 'Query', dividendHistoriesInFavoriteItemCategory?: Array<{ __typename?: 'DividendHistory', id?: string | null, amount?: number | null, paymentDate?: any | null, recordDate?: any | null, exDividendDate?: any | null, frequency?: string | null, flag?: string | null, currency?: { __typename?: 'Currency', id?: string | null, unit?: string | null, symbol?: string | null, decimalPoint?: number | null } | null, company?: { __typename?: 'Company', id?: string | null, ticker?: string | null, country?: string | null, paymentMonths?: Array<string | null> | null, logoUrl?: string | null, stockPrice?: { __typename?: 'StockPrice', price?: number | null, priceChangePercent?: string | null } | null } | null } | null> | null };

export type DividendHistoriesInCompanyQueryVariables = Exact<{
  companyId: Scalars['ID'];
}>;


export type DividendHistoriesInCompanyQuery = { __typename?: 'Query', dividendHistoriesInCompany?: Array<{ __typename?: 'DividendHistory', id?: string | null, amount?: number | null, paymentDate?: any | null, recordDate?: any | null, exDividendDate?: any | null, frequency?: string | null, flag?: string | null, currency?: { __typename?: 'Currency', id?: string | null, unit?: string | null, symbol?: string | null, decimalPoint?: number | null } | null, company?: { __typename?: 'Company', id?: string | null, ticker?: string | null, country?: string | null, paymentMonths?: Array<string | null> | null, logoUrl?: string | null, stockPrice?: { __typename?: 'StockPrice', price?: number | null, priceChangePercent?: string | null } | null } | null } | null> | null };

export type DividendCalendarForExternalChannelQueryVariables = Exact<{
  channelName?: InputMaybe<ExternalChannel>;
  date?: InputMaybe<Scalars['String']>;
}>;


export type DividendCalendarForExternalChannelQuery = { __typename?: 'Query', dividendCalendarForExternalChannel?: Array<{ __typename?: 'DividendHistory', id?: string | null, amount?: number | null, paymentDate?: any | null, recordDate?: any | null, exDividendDate?: any | null, frequency?: string | null, flag?: string | null, currency?: { __typename?: 'Currency', id?: string | null, unit?: string | null, symbol?: string | null, decimalPoint?: number | null } | null, company?: { __typename?: 'Company', id?: string | null, ticker?: string | null, country?: string | null, paymentMonths?: Array<string | null> | null, logoUrl?: string | null, companyName?: { __typename?: 'CompanyNameLanguage', ko?: string | null, en?: string | null } | null, stockPrice?: { __typename?: 'StockPrice', price?: number | null, priceChangePercent?: string | null } | null } | null } | null> | null };

export const DividendHistoryFieldsFragmentDoc = gql`
    fragment DividendHistoryFields on DividendHistory {
  id
  amount
  paymentDate
  recordDate
  exDividendDate
  frequency
  flag
  currency {
    id
    unit
    symbol
    decimalPoint
  }
  company {
    id
    ticker
    country
    companyName {
      ko
      en
    }
    stockPrice {
      price
      priceChangePercent
    }
    paymentMonths
    logoUrl
  }
}
    `;
export const DividendHistoryFieldsWithoutCompanyNameFragmentDoc = gql`
    fragment DividendHistoryFieldsWithoutCompanyName on DividendHistory {
  id
  amount
  paymentDate
  recordDate
  exDividendDate
  frequency
  flag
  currency {
    id
    unit
    symbol
    decimalPoint
  }
  company {
    id
    ticker
    country
    stockPrice {
      price
      priceChangePercent
    }
    paymentMonths
    logoUrl
  }
}
    `;
export const DividendCalendarDocument = gql`
    query DividendCalendar($date: String!) {
  dividendCalendar(date: $date) {
    ...DividendHistoryFields
  }
}
    ${DividendHistoryFieldsFragmentDoc}`;

/**
 * __useDividendCalendarQuery__
 *
 * To run a query within a React component, call `useDividendCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useDividendCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDividendCalendarQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDividendCalendarQuery(baseOptions: Apollo.QueryHookOptions<DividendCalendarQuery, DividendCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DividendCalendarQuery, DividendCalendarQueryVariables>(DividendCalendarDocument, options);
      }
export function useDividendCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DividendCalendarQuery, DividendCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DividendCalendarQuery, DividendCalendarQueryVariables>(DividendCalendarDocument, options);
        }
export type DividendCalendarQueryHookResult = ReturnType<typeof useDividendCalendarQuery>;
export type DividendCalendarLazyQueryHookResult = ReturnType<typeof useDividendCalendarLazyQuery>;
export type DividendCalendarQueryResult = Apollo.QueryResult<DividendCalendarQuery, DividendCalendarQueryVariables>;
export const DividendHistoriesDocument = gql`
    query DividendHistories($companyIds: [ID!]!) {
  dividendHistories(companyIds: $companyIds) {
    ...DividendHistoryFields
  }
}
    ${DividendHistoryFieldsFragmentDoc}`;

/**
 * __useDividendHistoriesQuery__
 *
 * To run a query within a React component, call `useDividendHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDividendHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDividendHistoriesQuery({
 *   variables: {
 *      companyIds: // value for 'companyIds'
 *   },
 * });
 */
export function useDividendHistoriesQuery(baseOptions: Apollo.QueryHookOptions<DividendHistoriesQuery, DividendHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DividendHistoriesQuery, DividendHistoriesQueryVariables>(DividendHistoriesDocument, options);
      }
export function useDividendHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DividendHistoriesQuery, DividendHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DividendHistoriesQuery, DividendHistoriesQueryVariables>(DividendHistoriesDocument, options);
        }
export type DividendHistoriesQueryHookResult = ReturnType<typeof useDividendHistoriesQuery>;
export type DividendHistoriesLazyQueryHookResult = ReturnType<typeof useDividendHistoriesLazyQuery>;
export type DividendHistoriesQueryResult = Apollo.QueryResult<DividendHistoriesQuery, DividendHistoriesQueryVariables>;
export const DividendHistoriesInPortfoliosDocument = gql`
    query DividendHistoriesInPortfolios($slugs: [String!]!) {
  dividendHistoriesInPortfolios(portfolioSlugs: $slugs) {
    ...DividendHistoryFieldsWithoutCompanyName
  }
}
    ${DividendHistoryFieldsWithoutCompanyNameFragmentDoc}`;

/**
 * __useDividendHistoriesInPortfoliosQuery__
 *
 * To run a query within a React component, call `useDividendHistoriesInPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useDividendHistoriesInPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDividendHistoriesInPortfoliosQuery({
 *   variables: {
 *      slugs: // value for 'slugs'
 *   },
 * });
 */
export function useDividendHistoriesInPortfoliosQuery(baseOptions: Apollo.QueryHookOptions<DividendHistoriesInPortfoliosQuery, DividendHistoriesInPortfoliosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DividendHistoriesInPortfoliosQuery, DividendHistoriesInPortfoliosQueryVariables>(DividendHistoriesInPortfoliosDocument, options);
      }
export function useDividendHistoriesInPortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DividendHistoriesInPortfoliosQuery, DividendHistoriesInPortfoliosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DividendHistoriesInPortfoliosQuery, DividendHistoriesInPortfoliosQueryVariables>(DividendHistoriesInPortfoliosDocument, options);
        }
export type DividendHistoriesInPortfoliosQueryHookResult = ReturnType<typeof useDividendHistoriesInPortfoliosQuery>;
export type DividendHistoriesInPortfoliosLazyQueryHookResult = ReturnType<typeof useDividendHistoriesInPortfoliosLazyQuery>;
export type DividendHistoriesInPortfoliosQueryResult = Apollo.QueryResult<DividendHistoriesInPortfoliosQuery, DividendHistoriesInPortfoliosQueryVariables>;
export const DividendHistoriesInFavoriteItemCategoryDocument = gql`
    query DividendHistoriesInFavoriteItemCategory($slug: String!) {
  dividendHistoriesInFavoriteItemCategory(favoriteItemCategorySlug: $slug) {
    ...DividendHistoryFieldsWithoutCompanyName
  }
}
    ${DividendHistoryFieldsWithoutCompanyNameFragmentDoc}`;

/**
 * __useDividendHistoriesInFavoriteItemCategoryQuery__
 *
 * To run a query within a React component, call `useDividendHistoriesInFavoriteItemCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDividendHistoriesInFavoriteItemCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDividendHistoriesInFavoriteItemCategoryQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDividendHistoriesInFavoriteItemCategoryQuery(baseOptions: Apollo.QueryHookOptions<DividendHistoriesInFavoriteItemCategoryQuery, DividendHistoriesInFavoriteItemCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DividendHistoriesInFavoriteItemCategoryQuery, DividendHistoriesInFavoriteItemCategoryQueryVariables>(DividendHistoriesInFavoriteItemCategoryDocument, options);
      }
export function useDividendHistoriesInFavoriteItemCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DividendHistoriesInFavoriteItemCategoryQuery, DividendHistoriesInFavoriteItemCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DividendHistoriesInFavoriteItemCategoryQuery, DividendHistoriesInFavoriteItemCategoryQueryVariables>(DividendHistoriesInFavoriteItemCategoryDocument, options);
        }
export type DividendHistoriesInFavoriteItemCategoryQueryHookResult = ReturnType<typeof useDividendHistoriesInFavoriteItemCategoryQuery>;
export type DividendHistoriesInFavoriteItemCategoryLazyQueryHookResult = ReturnType<typeof useDividendHistoriesInFavoriteItemCategoryLazyQuery>;
export type DividendHistoriesInFavoriteItemCategoryQueryResult = Apollo.QueryResult<DividendHistoriesInFavoriteItemCategoryQuery, DividendHistoriesInFavoriteItemCategoryQueryVariables>;
export const DividendHistoriesInCompanyDocument = gql`
    query DividendHistoriesInCompany($companyId: ID!) {
  dividendHistoriesInCompany(companyId: $companyId) {
    ...DividendHistoryFieldsWithoutCompanyName
  }
}
    ${DividendHistoryFieldsWithoutCompanyNameFragmentDoc}`;

/**
 * __useDividendHistoriesInCompanyQuery__
 *
 * To run a query within a React component, call `useDividendHistoriesInCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDividendHistoriesInCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDividendHistoriesInCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDividendHistoriesInCompanyQuery(baseOptions: Apollo.QueryHookOptions<DividendHistoriesInCompanyQuery, DividendHistoriesInCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DividendHistoriesInCompanyQuery, DividendHistoriesInCompanyQueryVariables>(DividendHistoriesInCompanyDocument, options);
      }
export function useDividendHistoriesInCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DividendHistoriesInCompanyQuery, DividendHistoriesInCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DividendHistoriesInCompanyQuery, DividendHistoriesInCompanyQueryVariables>(DividendHistoriesInCompanyDocument, options);
        }
export type DividendHistoriesInCompanyQueryHookResult = ReturnType<typeof useDividendHistoriesInCompanyQuery>;
export type DividendHistoriesInCompanyLazyQueryHookResult = ReturnType<typeof useDividendHistoriesInCompanyLazyQuery>;
export type DividendHistoriesInCompanyQueryResult = Apollo.QueryResult<DividendHistoriesInCompanyQuery, DividendHistoriesInCompanyQueryVariables>;
export const DividendCalendarForExternalChannelDocument = gql`
    query DividendCalendarForExternalChannel($channelName: ExternalChannel, $date: String) {
  dividendCalendarForExternalChannel(channelName: $channelName, date: $date) {
    ...DividendHistoryFields
  }
}
    ${DividendHistoryFieldsFragmentDoc}`;

/**
 * __useDividendCalendarForExternalChannelQuery__
 *
 * To run a query within a React component, call `useDividendCalendarForExternalChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useDividendCalendarForExternalChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDividendCalendarForExternalChannelQuery({
 *   variables: {
 *      channelName: // value for 'channelName'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDividendCalendarForExternalChannelQuery(baseOptions?: Apollo.QueryHookOptions<DividendCalendarForExternalChannelQuery, DividendCalendarForExternalChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DividendCalendarForExternalChannelQuery, DividendCalendarForExternalChannelQueryVariables>(DividendCalendarForExternalChannelDocument, options);
      }
export function useDividendCalendarForExternalChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DividendCalendarForExternalChannelQuery, DividendCalendarForExternalChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DividendCalendarForExternalChannelQuery, DividendCalendarForExternalChannelQueryVariables>(DividendCalendarForExternalChannelDocument, options);
        }
export type DividendCalendarForExternalChannelQueryHookResult = ReturnType<typeof useDividendCalendarForExternalChannelQuery>;
export type DividendCalendarForExternalChannelLazyQueryHookResult = ReturnType<typeof useDividendCalendarForExternalChannelLazyQuery>;
export type DividendCalendarForExternalChannelQueryResult = Apollo.QueryResult<DividendCalendarForExternalChannelQuery, DividendCalendarForExternalChannelQueryVariables>;
export const namedOperations = {
  Query: {
    DividendCalendar: 'DividendCalendar',
    DividendHistories: 'DividendHistories',
    DividendHistoriesInPortfolios: 'DividendHistoriesInPortfolios',
    DividendHistoriesInFavoriteItemCategory: 'DividendHistoriesInFavoriteItemCategory',
    DividendHistoriesInCompany: 'DividendHistoriesInCompany',
    DividendCalendarForExternalChannel: 'DividendCalendarForExternalChannel'
  },
  Fragment: {
    DividendHistoryFields: 'DividendHistoryFields',
    DividendHistoryFieldsWithoutCompanyName: 'DividendHistoryFieldsWithoutCompanyName'
  }
}